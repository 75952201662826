"use client";

import { makePluralizer } from "@uplift-ltd/strings";
import cx from "clsx";
import { debounce } from "lodash-es";
import React, {
  ReactElement,
  ReactNode,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface OffscreenElementsShape {
  addElement: (el: HTMLElement) => void;
  elements: HTMLElement[];
  removeElement: (el: HTMLElement) => void;
}

export const OffscreenElementsContext = React.createContext<OffscreenElementsShape>({
  addElement: () => {
    /* noop */
  },
  elements: [],
  removeElement: () => {
    /* noop */
  },
});

const remainingErrorsText = makePluralizer({ plural: "more errors", singular: "more error" });

export const OffscreenElementsProvider = ({ children }: { children: ReactNode }) => {
  const [elements, setElements] = useState<HTMLElement[]>([]);

  const addElement = (el: HTMLElement) => setElements(prev => Array.from(new Set([...prev, el])));

  const removeElement = (el: HTMLElement) => setElements(prev => prev.filter(item => item !== el));

  const value = useMemo(() => {
    return { addElement, elements, removeElement };
  }, [elements]);

  return (
    <OffscreenElementsContext.Provider value={value}>{children}</OffscreenElementsContext.Provider>
  );
};

export const useOffscreenElements = () => useContext(OffscreenElementsContext);

export const RegisterOffscreenElement = ({ children }: { children: ReactElement }) => {
  const { addElement, removeElement } = useContext(OffscreenElementsContext);
  const errorRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const el = errorRef.current;
    if (!el)
      return () => {
        /* noop */
      };

    addElement(el);

    return () => removeElement(el);

    // Only want this hook to run on first mount, do this properly with useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childElement = React.Children.only(children);

  if (!childElement) return null;

  return React.cloneElement(childElement, { ref: errorRef });
};

const scrollToElement = (element: HTMLElement) => {
  const left = element.parentElement?.offsetLeft || element.offsetLeft;
  const top = element.parentElement?.offsetTop || element.offsetTop;

  // scroll to the first element, with a buffer
  window.scrollTo({
    behavior: "smooth",
    left,
    top: top - 100,
  });
};

const getElementsAboveViewport = <T extends HTMLElement = HTMLDivElement>(elements: T[]) =>
  elements.filter(e => e.getBoundingClientRect().y < 0);

const getElementsBelowViewport = <T extends HTMLElement = HTMLDivElement>(elements: T[]) =>
  elements.filter(e => e.getBoundingClientRect().y > window.innerHeight);

const styles = {
  button: cx("m-0 inline-block p-0 leading-6 text-white underline"),
  tooltip: cx(
    "fixed right-6 z-20 max-w-xs appearance-none bg-red-500 px-4 py-2 text-center text-sm leading-6 text-white"
  ),
  triangle: cx("absolute left-1/2 size-0 -translate-x-1/2 border-x-[12px] border-x-transparent"),
};

interface ErrorPositionState {
  down: number;
  errorElementsDown: HTMLElement[];
  errorElementsUp: HTMLElement[];
  up: number;
}

export const OffscreenElementHint = () => {
  const { elements: errorElements } = useOffscreenElements();
  const [errorPositions, setErrorPositions] = useState<ErrorPositionState>({
    down: 0,
    errorElementsDown: [],
    errorElementsUp: [],
    up: 0,
  });

  React.useEffect(() => {
    const scrollHandler = debounce(() => {
      const errorElementsUp = getElementsAboveViewport(errorElements);
      const errorElementsDown = getElementsBelowViewport(errorElements);

      setErrorPositions({
        down: errorElementsDown.length,
        errorElementsDown,
        errorElementsUp,
        up: errorElementsUp.length,
      });
    }, 100);

    scrollHandler();

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [errorElements]);

  const scrollToFirst = () => {
    const [firstErrorElement] = errorPositions.errorElementsUp;

    if (firstErrorElement) {
      scrollToElement(firstErrorElement);
    }
  };

  const scrollToNext = () => {
    const [nextErrorElement] = errorPositions.errorElementsDown;

    if (nextErrorElement) {
      scrollToElement(nextErrorElement);
    }
  };

  return (
    <>
      {errorPositions.up > 0 && (
        <div className={cx(styles.tooltip, "top-5")}>
          <button className={styles.button} onClick={scrollToFirst} type="button">
            {errorPositions.errorElementsUp[0].textContent}
          </button>
          {errorPositions.up > 1 && (
            <span className="whitespace-nowrap">
              &nbsp;and {remainingErrorsText(errorPositions.up - 1)}
            </span>
          )}
          <div className={cx(styles.triangle, "bottom-full border-b-[10px] border-red-500")} />
        </div>
      )}

      {errorPositions.down > 0 && (
        <div className={cx(styles.tooltip, "bottom-5")}>
          <button className={styles.button} onClick={scrollToNext} type="button">
            {errorPositions.errorElementsDown[0].textContent}
          </button>
          {errorPositions.down > 1 && (
            <span className="whitespace-nowrap">
              &nbsp;and {remainingErrorsText(errorPositions.down - 1)}
            </span>
          )}

          <div className={cx(styles.triangle, "bottom-full border-t-[10px] border-red-500")} />
        </div>
      )}
    </>
  );
};
