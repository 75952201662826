"use client";

import cx from "clsx";
import React from "react";
import CSSTransition, { CSSTransitionClassNames } from "react-transition-group/CSSTransition";

const fadeInStyles = {
  appear: cx("opacity-0"),
  appearActive: cx("opacity-100 transition-opacity duration-500"),
  appearDone: cx("opacity-100"),
  enter: cx("opacity-0"),
  enterActive: cx("opacity-100 transition-opacity duration-500"),
  enterDone: cx("opacity-100"),
  exit: cx("opacity-100"),
  exitActive: cx("opacity-0 transition-opacity duration-500"),
  preEnter: cx("group-[.js]/root:opacity-0"),
};

export interface FadeInProps {
  children: React.FunctionComponentElement<{
    className?: string;
    ref?: React.Ref<HTMLElement>;
  }>;
  inView: boolean;
}

function FadeIn({ children, inView }: FadeInProps) {
  const child = React.Children.only(children);
  const clonedElement = React.cloneElement(child, {
    className: cx(child.props.className, !inView && fadeInStyles.preEnter),
  });
  return (
    <CSSTransition classNames={fadeInStyles as CSSTransitionClassNames} in={inView} timeout={500}>
      {clonedElement}
    </CSSTransition>
  );
}

export default FadeIn;

export { fadeInStyles };
