"use client";

import { trackClickTextContent } from "~/helpers/analytics";
import { buttonStyles } from "./Button.styles";
import { ButtonCommonProps } from "./Button.types";

type AnchorElementProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

export type AnchorButtonProps = ButtonCommonProps &
  Pick<AnchorElementProps, "className" | "href" | "id" | "onClick" | "rel" | "target">;

const AnchorButton = ({
  children,
  className,
  color = "blue",
  href,
  loading,
  onClick,
  size = "md",
  ...otherProps
}: React.PropsWithChildren<AnchorButtonProps>) => {
  return (
    <a
      className={buttonStyles({ className, loading, size, theme: color })}
      href={href}
      onClick={e => {
        trackClickTextContent(e);
        onClick?.(e);
      }}
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default AnchorButton;
