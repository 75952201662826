"use client";

import { useEffect } from "react";
import { umami } from "~/helpers/umami";

interface TrackViewProps {
  children: React.ReactNode;
  name: string;
}

export function TrackView({ children, name }: TrackViewProps) {
  useEffect(() => {
    if (typeof children === "string") {
      umami.trackEvent({ data: { label: children }, name });
    }
  }, [children, name]);
  return null;
}
