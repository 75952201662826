/* eslint-disable import/no-named-default */
import { Suspense } from "react";
import { FadeContentWrapperProps, default as FadeContentWrapperRaw } from "./FadeContentWrapper";
import { FadeInProps, default as FadeInRaw } from "./FadeIn";
import { FadeInViewProps, default as FadeInViewRaw } from "./FadeInView";

export { type FadeInProps, fadeInStyles } from "./FadeIn";

export const FadeIn = function FadeIn(props: FadeInProps) {
  return (
    <Suspense>
      <FadeInRaw {...props} />
    </Suspense>
  );
};

export function FadeInView(props: FadeInViewProps) {
  return (
    <Suspense>
      <FadeInViewRaw {...props} />
    </Suspense>
  );
}

export function FadeContentWrapper(props: FadeContentWrapperProps) {
  return (
    <Suspense>
      <FadeContentWrapperRaw {...props} />
    </Suspense>
  );
}
