"use strict";
exports.__esModule = true;
// NOTE: You need to run npm run build:scripts to use this
var thumbor_sdk_1 = require("thumbor-sdk");
var HOST = process.env.NEXT_PUBLIC_SITE_URL;
var THUMBOR_HOST = process.env.NEXT_PUBLIC_THUMBOR_URL;
var DEFAULT_QUALITY = 75;
var unsafeThumbor = new thumbor_sdk_1.UnsafeThumbor({
  endpoint: THUMBOR_HOST || "",
});
function thumborLoader(_a) {
  var src = _a.src,
    width = _a.width,
    quality = _a.quality;
  if (!THUMBOR_HOST) return src;
  if (!src.startsWith("/") || src.endsWith("svg") || src.endsWith("gif")) {
    return src;
  }
  var path = "".concat(HOST).concat(src);
  return unsafeThumbor.url(path, {
    width: width,
    filters: { quality: quality || DEFAULT_QUALITY, no_upscale: "" },
  });
}
module.exports = thumborLoader;
