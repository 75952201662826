"use client";

import React from "react";
import { trackClickTextContent } from "~/helpers/analytics";
import { buttonStyles } from "./Button.styles";
import { ButtonCommonProps } from "./Button.types";

type ButtonElementProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type ButtonProps = ButtonCommonProps &
  Pick<ButtonElementProps, "children" | "className" | "disabled" | "id" | "onClick" | "type">;

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
  (
    {
      children,
      className,
      color = "blue",
      disabled,
      loading,
      onClick,
      size = "md",
      type = "button",
      ...otherProps
    },
    ref
  ) => {
    return (
      <button
        className={buttonStyles({ className, loading, size, theme: color })}
        disabled={disabled || loading}
        onClick={e => {
          trackClickTextContent(e);
          onClick?.(e);
        }}
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...otherProps}
      >
        {children}
      </button>
    );
  }
);
Button.displayName = "Button";

export default Button;

interface IconButtonProps extends ButtonProps {
  icon?: React.ReactNode;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, icon, type = "button", ...rest }, ref) => (
    <button
      className={className}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...rest}
    >
      {icon || children}
    </button>
  )
);

IconButton.displayName = "IconButton";
