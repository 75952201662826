import { IS_SSR } from "@uplift-ltd/constants";

/* eslint-disable prefer-destructuring */
export const IS_PRODUCTION =
  process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_IS_PRODUCTION === "true";

export const GRAPHQL_HOST = process.env.GRAPHQL_HOST || "http://127.0.0.1:3000";

export const GRAPHQL_AUTH_ENDPOINT = "/api/auth/graphql/";

export const GRAPHQL_UNAUTH_ENDPOINT = "/api/graphql/";

export const GRAPHQL_AUTH_URL = IS_SSR
  ? `${GRAPHQL_HOST}${GRAPHQL_AUTH_ENDPOINT}`
  : GRAPHQL_AUTH_ENDPOINT;

export const GRAPHQL_UNAUTH_URL = IS_SSR
  ? `${GRAPHQL_HOST}${GRAPHQL_UNAUTH_ENDPOINT}`
  : GRAPHQL_UNAUTH_ENDPOINT;

export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const DRIFT_APP_ID = process.env.NEXT_PUBLIC_DRIFT_APP_ID;

export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;

export const UMAMI_HOST = process.env.NEXT_PUBLIC_UMAMI_HOST;
export const UMAMI_WEBSITE_ID = process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID;

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL;

export const POST_PER_PAGE = 10;
export const SEARCH_DEBOUNCE_DELAY_MS = 200;

export const PUSHER_KEY = process.env.NEXT_PUBLIC_PUSHER_KEY || "";
export const PUSHER_HOST = process.env.NEXT_PUBLIC_PUSHER_HOST || "127.0.0.1";
export const PUSHER_PORT = process.env.NEXT_PUBLIC_PUSHER_PORT;
export const PUSHER_USE_TLS = process.env.NEXT_PUBLIC_PUSHER_USE_TLS === "true";
