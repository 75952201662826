import { setUser } from "@sentry/browser";
import { useUserContext } from "@uplift-ltd/use-user-context";
import { useEffect } from "react";

export function IdentifyUser({ children }: React.PropsWithChildren) {
  const { currentUser } = useUserContext();
  useEffect(() => {
    if (currentUser) {
      setUser({ email: currentUser.email, id: currentUser.id, name: currentUser.name });
    }
  }, [currentUser]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
