export const HOME_URL = "/";

export const RSS_URL = "/feed/";

export const LOGIN_URL = "/login/";
export const MAGIC_LINK_URL = "/magic-link/";
export const LOGOUT_URL = "/logout/";

export const CONTACT_US_URL = "/contact-us/";
export const CAREERS_URL = "/careers/";
export const BLOG_URL = "/blog/";

export const ROADMAPPING_URL = "/roadmapping/";

export const ASSESSMENT_URL = "/careers/assessments/:assessmentId/";
export const START_ASSESSMENT_URL = "/careers/assessments/:assessmentId/start/";
export const TAKE_ASSESSMENT_URL = "/careers/assessments/:assessmentId/take/";

export const SUBMIT_CODING_EXERCISE_URL = "/careers/exercises/submit";

export const TERMS_AND_CONDITION_URL = "/terms/";
export const PRIVACY_POLICY_URL = "/privacy/";

export const CASE_STUDY_URL = "/case-studies/:caseStudySlug/";

export const ADMIN_CANDIDATE_CONTACTS_URL = "/admin/contacts/candidate/";
export const ADMIN_CODING_EXERCISE_DETAIL_URL = "/admin/careers/coding-exercises/:repoId/";
export const ADMIN_CODING_EXERCISE_PULL_REQUEST_SCORE_DETAIL_URL =
  "/admin/careers/coding-exercises/:repoId/pull-requests/:pullRequestNumber/scores/:scoreId";
export const ADMIN_CONTACTS_URL = "/admin/contacts/";
export const ADMIN_CONTACT_SUBMISSION_URL = "/admin/contacts/:contactSubmissionId/";
export const ADMIN_DASHBOARD_URL = "/admin/";
export const ADMIN_LIST_ASSESSMENTS_URL = "/admin/careers/assessments/";
export const ADMIN_LIST_CODING_EXERCISES_URL = "/admin/careers/coding-exercises/";
export const ADMIN_NEW_CODING_EXERCISE_URL = "/admin/careers/coding-exercises/new/";
export const ADMIN_NOTIFY_ASSESSMENT_RESULT_URL = "/admin/careers/assessments/notify-result/";
export const ADMIN_REQUEST_ASSESSMENT_URL = "/admin/careers/assessments/request/";
export const ADMIN_USERS_LIST_URL = "/admin/users";
export const ADMIN_USER_URL = "/admin/users/:userId";
export const ADMIN_VIEW_FILE_URL = "/admin/files/:fileAttachmentId/";

export const WEBHOOK_GITHUB_REPOSITORY = "/api/hooks/github/:repositoryOwnerAndName";

export const ADMIN_URLS: { href: string; label: string }[] = [
  { href: ADMIN_LIST_ASSESSMENTS_URL, label: "Assessments" },
  { href: ADMIN_REQUEST_ASSESSMENT_URL, label: "Request Assessment" },
  { href: ADMIN_NOTIFY_ASSESSMENT_RESULT_URL, label: "Notify Assessment Result" },
  { href: ADMIN_LIST_CODING_EXERCISES_URL, label: "Coding Exercises" },
  { href: ADMIN_NEW_CODING_EXERCISE_URL, label: "New Coding Exercise" },
  { href: ADMIN_CONTACTS_URL, label: "Contacts" },
  { href: ADMIN_CANDIDATE_CONTACTS_URL, label: "Candidate Contacts" },
  { href: ADMIN_USERS_LIST_URL, label: "Users" },
  { href: LOGOUT_URL, label: "Log out" },
];

export const MEET_PAUL_URL = "https://meet-paul.uplift.ltd/project-inquiry";
