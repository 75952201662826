"use client";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { safeJoinWithSpace } from "@uplift-ltd/strings";
import cx from "clsx";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { usePathname } from "next/navigation";
import React, { useCallback } from "react";
import { trackClickTextContent } from "~/helpers/analytics";
import { isExternalUrl } from "~/helpers/urls";

// TODO: make 'primary' the default?
//       waiting on https://github.com/uplift-ltd/uplift-marketing/pull/404
const LinkVariantStyles = {
  default: "",
  primary: "text-blue-500 hover:text-blue-700",
};

type LinkVariant = keyof typeof LinkVariantStyles;

export type LinkProps = {
  activeClassName?: string;
  exact?: boolean;
  ref?: never;
  variant?: LinkVariant;
} & JSX.IntrinsicElements["a"] &
  NextLinkProps;

const Link = ({
  activeClassName,
  as,
  children,
  className,
  exact,
  href,
  locale,
  onClick,
  passHref,
  prefetch,
  replace,
  scroll,
  shallow,
  variant = "default",
  ...rest
}: React.PropsWithChildren<LinkProps>) => {
  let pathname = usePathname();

  if (!pathname.endsWith("/")) {
    pathname += "/";
  }

  const isActive = pathname === href;
  const isPartiallyActive = href !== "/" && pathname.startsWith(href) && !exact;
  const isExternal = isExternalUrl(href);
  const openInNewWindow = rest.target === "_blank";

  const handleClick: LinkProps["onClick"] = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      onClick?.(e);
      trackClickTextContent(e);
    },
    [onClick]
  );

  const anchorChildren = (
    <>
      {children}
      {openInNewWindow ? (
        <ArrowTopRightOnSquareIcon className="ml-1 inline-block size-4 align-sub" />
      ) : null}
    </>
  );

  const anchorProps = {
    ...rest,
    "aria-current": isActive || isPartiallyActive ? rest["aria-current"] || "page" : undefined,
    className: cx(
      LinkVariantStyles[variant],
      className,
      (isActive || isPartiallyActive) && activeClassName
    ),
    onClick,
  };

  if (isExternal) {
    const rel = !anchorProps.rel?.includes("noreferrer")
      ? safeJoinWithSpace(anchorProps.rel, "noreferrer")
      : anchorProps.rel;

    return (
      <a {...anchorProps} href={href} rel={rel}>
        {anchorChildren}
      </a>
    );
  }

  return (
    <NextLink
      {...anchorProps}
      aria-current={
        isActive || isPartiallyActive ? anchorProps["aria-current"] || "page" : undefined
      }
      as={as}
      className={cx(anchorProps.className, (isActive || isPartiallyActive) && activeClassName)}
      href={href}
      locale={locale}
      onClick={handleClick}
      passHref={passHref}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
    >
      {children}
    </NextLink>
  );
};

export default Link;
