"use client";

import React from "react";
import { useInView } from "react-intersection-observer";
import FadeIn, { FadeInProps } from "./FadeIn";

export interface FadeInViewProps {
  children: FadeInProps["children"];
}

export default function FadeInView({ children }: FadeInViewProps) {
  const { inView, ref } = useInView({ triggerOnce: true });
  const child = React.Children.only(children);
  return (
    <FadeIn inView={inView}>
      {React.cloneElement(child, {
        ref,
      })}
    </FadeIn>
  );
}
