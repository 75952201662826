import cx from "clsx";
import React from "react";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, style }, ref) => {
    return (
      <div className={cx("up-container", className)} ref={ref} style={style}>
        {children}
      </div>
    );
  }
);

Container.displayName = "Container";

export default Container;
