"use client";

import { useInterval } from "@uplift-ltd/use-interval";
import { useState } from "react";

interface UseCounterProps {
  duration?: number;
  startCounting?: boolean;
  value: number;
}

export const useCounter = ({ duration = 2000, startCounting = true, value }: UseCounterProps) => {
  const interval = Math.floor(duration / value);
  const [counter, setCounter] = useState(0);

  useInterval(
    () => {
      if (counter < value) {
        setCounter(val => val + 1);
      }
    },
    startCounting && counter < value ? interval : null
  );

  return {
    counter,
  };
};
