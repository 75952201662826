import cx from "clsx";
import Container from "~/components/common/Container";

interface SectionPanelProps {
  children?: React.ReactNode;
  classNameContainer?: string;
  postWidth?: boolean;
}

const SectionPanel = ({ children, classNameContainer, postWidth }: SectionPanelProps) => (
  <section className="not-prose my-16 border-y bg-gray-50 py-16 dark:bg-gray-950">
    <Container
      className={cx("flex w-full justify-center", { "!max-w-post": postWidth }, classNameContainer)}
    >
      {children}
    </Container>
  </section>
);

export default SectionPanel;
