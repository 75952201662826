"use client";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cx from "clsx";
import React, { useEffect, useRef } from "react";
import { isExternalUrl } from "~/helpers/urls";
import { useHighlight } from "~/hooks/useHighlight";

const handleExternalLinks: React.MouseEventHandler<HTMLElement> = e => {
  if (e.target instanceof HTMLAnchorElement) {
    // test raw value instead of resolved value which can include domain
    if (isExternalUrl(e.target.attributes?.getNamedItem("href")?.value || e.target.href)) {
      e.preventDefault();
      window.open(e.target.href, "_blank");
    }
  }
};

interface PostContentProps {
  className?: string;
}

const PostContent = ({ children, className }: React.PropsWithChildren<PostContentProps>) => {
  const articleRef = useRef<HTMLDivElement>(null);
  const hljs = useHighlight();

  useEffect(() => {
    if (hljs && articleRef.current) {
      articleRef.current.querySelectorAll("pre code").forEach(el => {
        hljs.highlightElement(el as HTMLElement);
      });
    }
  }, [hljs]);

  return (
    <article
      className={cx("text-lg leading-relaxed up-post-container", className)}
      onClick={handleExternalLinks}
      ref={articleRef}
    >
      {children}
    </article>
  );
};

export default PostContent;
