"use client";

import { scrollToAnchorHref } from "~/helpers/urls";
import AnchorButton, { AnchorButtonProps } from "./AnchorButton";

interface ScrollToButtonProps extends AnchorButtonProps {
  delta?: number;
  href: string;
}

const ScrollToButton = ({
  children,
  delta = -50,
  href,
  onClick,
  ...otherProps
}: React.PropsWithChildren<ScrollToButtonProps>) => {
  return (
    <AnchorButton
      href={href}
      onClick={e => {
        scrollToAnchorHref(e, delta);
        onClick?.(e);
      }}
      {...otherProps}
    >
      {children}
    </AnchorButton>
  );
};

export default ScrollToButton;
