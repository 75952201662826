"use client";

import { useInView } from "react-intersection-observer";
import SectionPanel from "~/components/CaseStudy/SectionPanel";
import { FadeIn } from "~/components/common/Transitions";
import { useCounter } from "~/hooks/useCounter";

interface ProjectStatisticsProps {
  statistics: Statistic[];
}

interface Statistic {
  descriptionPrimary: string;
  descriptionSecondary: string;
  rawValue: number;
  valueSymbol?: string;
}

const AnimatedNumber = ({ inView, value }: { inView: boolean; value: number }) => {
  const { counter } = useCounter({ startCounting: inView, value });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{counter}</>;
};

const ProjectStatistics = ({ statistics }: ProjectStatisticsProps) => {
  const { inView, ref } = useInView({ triggerOnce: true });

  return (
    <FadeIn inView={inView}>
      <section className="font-serif" ref={ref}>
        <SectionPanel classNameContainer="gap-10 lg:gap-16 flex-col lg:flex-row">
          {statistics.map(({ descriptionPrimary, descriptionSecondary, rawValue, valueSymbol }) => (
            <div className="flex flex-col lg:w-1/3" key={descriptionPrimary}>
              <span className="mb-2 font-serif text-5xl text-orange-600">
                <AnimatedNumber inView={inView} value={rawValue} />
                {valueSymbol}
              </span>
              <div>
                <span className="font-extrabold">{descriptionPrimary}</span>
                <span>&nbsp;{descriptionSecondary}</span>
              </div>
            </div>
          ))}
        </SectionPanel>
      </section>
    </FadeIn>
  );
};

export default ProjectStatistics;
