import FadeInView, { FadeInViewProps } from "./FadeInView";

export type FadeContentWrapperProps = FadeInViewProps;

export default function FadeContentWrapper({ children }: FadeContentWrapperProps) {
  return (
    <FadeInView>
      <div>{children}</div>
    </FadeInView>
  );
}
