import { IS_SSR } from "@uplift-ltd/constants";
import { UserContextProvider as NexusUserContextProvider } from "@uplift-ltd/use-user-context";
import { UserContextDocument, UserContextQuery } from "~/__generated__";
import { GRAPHQL_UNAUTH_URL } from "~/constants/constants";

export type UserContext = Required<UserContextQuery>;
export type CurrentUser = NonNullable<UserContext["currentUser"]>;

export { useAssertUserContext, useUserContext } from "@uplift-ltd/use-user-context";

interface UserContextProviderProps {
  skip?: boolean;
}

export const UserContextProvider = ({
  children,
  skip = IS_SSR,
}: React.PropsWithChildren<UserContextProviderProps>) => (
  <NexusUserContextProvider
    currentUserQuery={UserContextDocument}
    currentUserQueryOptions={{ context: { uri: GRAPHQL_UNAUTH_URL }, skip }}
  >
    {children}
  </NexusUserContextProvider>
);
