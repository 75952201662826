import { cva, cx } from "class-variance-authority";
import { ButtonColor, ButtonSize } from "./Button.types";

export const buttonStyles = cva(
  [
    "not-prose inline-block rounded border font-medium transition-colors duration-500 focus:outline-none focus:ring disabled:cursor-not-allowed disabled:opacity-50",
  ],
  {
    defaultVariants: {
      size: "md",
    },
    variants: {
      loading: {
        false: "",
        true: "animate-pulse",
      },
      size: {
        lg: cx("rounded-lg px-6 py-3 text-lg"),
        md: cx("px-4 py-2"),
        sm: cx("px-3 py-1"),
      } satisfies Record<ButtonSize, string>,
      // TODO: make link and outline separate
      theme: {
        blue: cx("border-blue-500 bg-blue-500 !text-white hover:border-blue-700 hover:bg-blue-700"),
        gray: cx(
          "dark:hvoer:bg-black border-gray-300 bg-gray-100 hover:bg-gray-300 dark:border-black dark:bg-gray-900"
        ),
        green: cx(
          "border-green-500 bg-green-500 !text-white hover:border-green-700 hover:bg-green-700"
        ),
        link: cx("inline !border-transparent !p-0 !text-blue-500 hover:!text-blue-700"),
        orange:
          "bg-orange-500 border-orange-500 !text-white hover:bg-orange-700 hover:border-orange-700",
        red: cx("border-red-500 bg-red-500 !text-white hover:border-red-700 hover:bg-red-700"),
        redOutline: cx(
          "border-white bg-white !text-red-500 hover:border-red-500 dark:border-black dark:bg-gray-950"
        ),
      } satisfies Record<ButtonColor, string>,
    },
  }
);
