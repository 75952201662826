
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Connection": [
      "AssessmentConnection",
      "AuthorConnection",
      "CodingExerciseRepoConnection",
      "ContactSubmissionConnection",
      "PageConnection",
      "PostConnection",
      "TagConnection",
      "UserConnection"
    ],
    "Edge": [
      "AssessmentEdge",
      "AuthorEdge",
      "CodingExerciseRepoEdge",
      "ContactSubmissionEdge",
      "PageEdge",
      "PostEdge",
      "TagEdge",
      "UserEdge"
    ],
    "MutationResult": [
      "CareersContactResult",
      "ClientContactResult",
      "InviteCollaboratorToGithubRepositoryResult",
      "LoginResult",
      "LogoutResult",
      "MagicLinkResult",
      "NewCodingExerciseResult",
      "NotifyAssessmentResultResult",
      "RecordAssessmentEventResult",
      "RequestAssessmentResult",
      "SubmitCodingExerciseResult",
      "SubscribeNewsletterResult",
      "TakeAssessmentResult",
      "UpdateAnswerResult",
      "UpdateAssessmentSessionResult",
      "UpdatePullRequestScoreResult",
      "VerifyAssessmentResult"
    ],
    "PostData": [
      "Page",
      "Post"
    ],
    "Question": [
      "CheckboxQuestion",
      "CodeQuestion",
      "RadioQuestion",
      "TextQuestion"
    ],
    "QuestionBase": [
      "CheckboxQuestion",
      "CodeQuestion",
      "RadioQuestion",
      "TextQuestion"
    ]
  }
};
      export default result;
    