import { useEffect, useState } from "react";

export function useHighlight() {
  const [hljs, setHLJS] = useState<import("highlight.js").HLJSApi | null>(null);

  useEffect(() => {
    let unmounted = false;
    const loadHighlight = async () => {
      const hl = (await import("highlight.js")).default;
      const hljsDefineGraphQL = (await import("highlightjs-graphql")).default;
      hljsDefineGraphQL(hl);
      if (!unmounted) {
        setHLJS(hl);
      }
      import("highlight.js/styles/github-dark.css");
    };

    loadHighlight();

    return () => {
      unmounted = true;
    };
  }, []);

  return hljs;
}
