export function isExternalUrl(url: string): boolean {
  return url.indexOf("://") !== -1 && !url.startsWith("#");
}

export function getPageSlug(slug: string) {
  return `/${slug.replace(/^pages\//, "")}`;
}

export function scrollToAnchorHref(
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  delta = -50
) {
  const ID_REGEX = /#(.+)/;
  const [, id] = ID_REGEX.exec(e.currentTarget.href) || [];
  const target = document.getElementById(id);

  if (target) {
    e.preventDefault();
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: Math.max(
        target.getBoundingClientRect().top + document.documentElement.scrollTop + delta,
        0
      ),
    });
  }
}
