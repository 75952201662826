"use client";

import { ApolloProvider } from "@apollo/client";
import { ConfigureClientOptions } from "@uplift-ltd/apollo";
import { IS_SSR } from "@uplift-ltd/constants";
import { PropsWithChildren } from "react";
import { UIDReset } from "react-uid";
import { UserContextQuery } from "~/__generated__";
import { GRAPHQL_AUTH_URL, GRAPHQL_UNAUTH_URL } from "~/constants/constants";
import { useApollo } from "~/helpers/apollo";
import { UserContextProvider } from "~/helpers/userContext";
import { IdentifyUser } from "./common/IdentifyUser";
import { OffscreenElementHint, OffscreenElementsProvider } from "./common/OffscreenElements";

interface ClientProvidersProps {
  currentUser: UserContextQuery["currentUser"];
  initialState?: ConfigureClientOptions["initialState"];
  isAuthenticated: boolean;
}

export function ClientProviders({
  children,
  currentUser: _currentUser,
  initialState,
  isAuthenticated,
}: PropsWithChildren<ClientProvidersProps>) {
  const client = useApollo({
    initialState,
    uri: isAuthenticated ? GRAPHQL_AUTH_URL : GRAPHQL_UNAUTH_URL,
  });
  return (
    <UIDReset>
      <ApolloProvider client={client}>
        <UserContextProvider skip={IS_SSR}>
          <OffscreenElementsProvider>
            <IdentifyUser />
            {children}
            <OffscreenElementHint />
          </OffscreenElementsProvider>
        </UserContextProvider>
      </ApolloProvider>
    </UIDReset>
  );
}
