import { UMAMI_HOST, UMAMI_WEBSITE_ID } from "~/constants/constants";

export interface UmamiPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any };
  hostname: string;
  language: string;
  name?: string;
  referrer: string;
  screen: string;
  title: string;
  url: string;
  website: string;
}

export interface UmamiBody {
  payload: UmamiPayload;
  type: "event";
}

interface UmamiOptions {
  websiteId: string;
}

type TrackViewOptions = {
  title?: string;
  url?: string;
};

export type TrackEventOptions = {
  data: object;
  name: string;
  title?: string;
  url?: string;
};

export class Umami {
  private websiteId: string;

  constructor({ websiteId }: UmamiOptions) {
    this.websiteId = websiteId;
  }

  private fetch(body: UmamiBody) {
    if (!this.websiteId) return undefined;
    return fetch(`${UMAMI_HOST}/api/send`, {
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        // "User-Agent": navigator.userAgent,
      },
      method: "POST",
    });
  }

  private send(body: Partial<UmamiPayload>) {
    return this.fetch({
      payload: {
        hostname: window.location.hostname,
        language: navigator.language,
        referrer: document.referrer,
        screen: `${window.screen.width}x${window.screen.height}`,
        ...body,
        title: body.title || document.title,
        url: body.url || window.location.pathname,
        website: this.websiteId,
      },
      type: "event",
    });
  }

  async trackEvent(options: TrackEventOptions) {
    this.send({
      data: options.data,
      name: options.name,
      title: options.title,
      url: options.url,
    });
  }

  async trackView(options: TrackViewOptions) {
    this.send({
      title: options.title,
      url: options.url,
    });
  }
}

export const umami = new Umami({ websiteId: UMAMI_WEBSITE_ID || "" });
