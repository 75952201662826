import { InMemoryCache } from "@apollo/client/cache";
import { getOperationName, relayStylePagination } from "@apollo/client/utilities";
import { ConfigureClientOptions, defaultBatchKey, initClient } from "@uplift-ltd/apollo";
import { IS_SSR } from "@uplift-ltd/constants";
import { makeUrl } from "@uplift-ltd/strings";
import { useMemo } from "react";
import { RecordAssessmentEventDocument } from "~/__generated__";
import possibleTypes from "~/__generated__/possibleTypes";
import { IS_PRODUCTION } from "~/constants/constants";
import { HOME_URL, LOGIN_URL } from "~/constants/urls";
import { relayStyleOffsetLimitPagination } from "~/helpers/apolloPagination";
import { captureException, captureMessage } from "~/helpers/sentry";

const recordAssessmentOperationName = getOperationName(RecordAssessmentEventDocument);

export type ConfigureClientProps = Pick<
  ConfigureClientOptions,
  "cookie" | "extraLinks" | "initialState" | "link" | "onGraphqlErrors" | "onNetworkError" | "uri"
>;

export const configureClient = ({
  cookie,
  extraLinks,
  initialState,
  link,
  onGraphqlErrors,
  onNetworkError,
  uri,
}: ConfigureClientProps = {}) => {
  const cache = new InMemoryCache({
    ...possibleTypes,
    typePolicies: {
      Assessment: {
        fields: {
          activeSession: {
            keyArgs: false,
          },
        },
      },
      Author: {
        fields: {
          posts: relayStylePagination(),
        },
        keyFields: ["slug"],
      },
      Page: {
        keyFields: ["slug"],
      },
      Post: {
        keyFields: ["slug"],
      },
      Query: {
        fields: {
          assessments: relayStylePagination(["searchQuery", "isCompleted"]),
          authors: relayStyleOffsetLimitPagination(),
          codingExerciseRepositories: relayStyleOffsetLimitPagination([
            "filter",
            "onlyWithPullRequests",
          ]),
          contactSubmissions: relayStylePagination(["types", "userId", "searchQuery"]),
          pages: relayStyleOffsetLimitPagination(["template"]),
          posts: relayStyleOffsetLimitPagination(["slugs"]),
          tags: relayStyleOffsetLimitPagination(),
          users: relayStylePagination(["types"]),
        },
      },
      Question: {
        keyFields: ["slug", "version"],
      },
      Showcase: {
        keyFields: ["slug"],
      },
      Tag: {
        fields: {
          posts: relayStylePagination(),
        },
        keyFields: ["slug"],
      },
      Testimonial: {
        keyFields: ["slug"],
      },
    },
  });

  const options: ConfigureClientOptions = {
    batchKey(operation) {
      if (operation.operationName === recordAssessmentOperationName) {
        return recordAssessmentOperationName + Math.random();
      }
      return defaultBatchKey(operation, typeof uri === "function" ? uri(operation) : uri || "");
    },
    cache,
    captureException,
    captureMessage: (message, ctx) => {
      // TODO: add something in nexus
      if (Array.isArray(ctx?.extra?.errors)) {
        const isNotFound = ctx.extra.errors.every(graphqlError => {
          return graphqlError?.extensions?.code === "NOT_FOUND";
        });
        if (isNotFound) {
          return;
        }
      }
      captureException(message, ctx);
    },
    connectToDevTools: !IS_PRODUCTION,
    cookie,
    extraLinks,
    initialState,
    onForbidden() {
      if (IS_SSR) {
        return;
      }
      window.location.assign(HOME_URL);
    },
    onGraphqlErrors(errors, operation) {
      onGraphqlErrors?.(errors, operation);
      console.error(errors, operation);
    },
    onNetworkError(err, operation) {
      onNetworkError?.(err, operation);
      console.error(err, operation);
    },
    onNotAuthorized() {
      if (IS_SSR) {
        return;
      }
      window.location.assign(makeUrl(LOGIN_URL, null, { next: window.location.pathname }));
    },
    uri,
  };

  if (link) {
    options.terminatingLink = link;
  }

  return initClient(options);
};

export function useApollo(options?: ConfigureClientProps) {
  const store = useMemo(
    () =>
      configureClient({
        ...(options || {}),
      }),
    [options]
  );
  return store;
}
